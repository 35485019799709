import React, { Ref, useEffect, useRef } from "react";
import FormComponent, { FormProps } from "./FormComponent";

interface FormTextAreaProps extends FormProps {
    placeholder?:string;
    name?:string;
    value?:string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function FormTextArea(props:FormTextAreaProps) {

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (textAreaRef.current) {
            auto_grow(textAreaRef.current);
        }
    }, [textAreaRef.current]);

    function auto_grow(element:HTMLTextAreaElement) {
        if (element && element.scrollHeight > 128) {
            element.style.height = "5px";
            element.style.height = (element.scrollHeight)+"px";
        }
    }

    const {extraClass, label, validationField} = props;
    
    return (
        
        <FormComponent
            extraClass={extraClass}
            label={label}
            validationField={validationField}
        >
            <textarea
                ref={textAreaRef}
                id={"id_" + props.name}
                name={props.name}
                className="form_container form_input text_white"
                placeholder={props.placeholder}
                onInput={(event) => {
                    auto_grow((event.target as HTMLTextAreaElement))
                }}
                value={props.value}
                onChange={props.onChange}
            >
            </textarea>
        </FormComponent>
    );
}